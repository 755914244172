/* eslint-disable */
import {TimeUtils} from "@/utils/TimeUtils";
import {SystemRole} from "@/utils/SystemRole";

export const MapUtils = {
    createCircleOptions() {
        return {
            strokeOpacity: 0,
            strokeWeight: 2,
            fillColor: "#EA1B0A",
            fillOpacity: 0.35,
        };
    },
    userReportsClusterRenderer: {
        render: ({count, position}) =>
        new google.maps.Marker({
                label: {text: String(count > 99 ? "99+" : count)},
                icon: require("@/assets/notification_bell_cluster.svg"),
                position,
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
            }),
    },
    createUserReportInfoWindowContent(marker, isPublic) {
        const popupDiv = document.createElement("div");
        popupDiv.classList.add("info-window");

        const header = document.createElement("header");
        header.classList.add("info-window-header");

        const contentDiv = document.createElement("div");
        contentDiv.classList.add("info-window-content");
        contentDiv.classList.add("info-window-content__user");

        const contentDivText = document.createElement("div");
        contentDivText.classList.add("info-window-content-text");

        const address = document.createElement("p");
        const addressContent = document.createTextNode("Adres zgłoszenia:");
        address.classList.add("info-window-content-address")

        const addressInput = document.createElement("p");
        const addressContentInput = document.createTextNode(marker.address);
        addressInput.classList.add("info-window-content-address-input")

        const start = document.createElement("p");
        start.classList.add("info-window-content-start-data")
        const startContent = document.createTextNode("Data zgłoszenia:");

        const startInput = document.createElement("p");
        startInput.classList.add("info-window-content-start-data-input")
        const startContentInput = document.createTextNode(new Date(marker.start).toLocaleString("pl-PL", TimeUtils.options))

        const comment = document.createElement("p");
        const commentContent = document.createTextNode("Dodatkowe informacje:");
        comment.classList.add("info-window-content-comment")

        const commentInput = document.createElement("p");
        const commentContentInput = document.createTextNode(marker.comment ? marker.comment : "Nie ma komentarza.");
        commentInput.classList.add("info-window-content-comment-input")

        let headerContent, remarkContent;

        if (marker.type === "WEB") {
            headerContent = document.createTextNode("Zgłoszenie użytkownika");
            header.classList.add("info-window-content-header-user")
            remarkContent = document.createTextNode
            ("Operator sieci został poinformowany o zdarzeniu. " +
                "Zgłoszenie oczekuje na potwierdzenie.");
        } else {
            headerContent = document.createTextNode("Zgłoszenie użytkownika przez infolinię");
            remarkContent = document.createTextNode
            ("Operator sieci został poinformowany o zdarzeniu. " +
                "Zgłoszenie zostało przyjęte przez pracownika infolinii.");
        }
        header.appendChild(headerContent);
        address.appendChild(addressContent);
        addressInput.appendChild(addressContentInput);
        start.appendChild(startContent);
        startInput.appendChild(startContentInput);
        comment.appendChild(commentContent);
        commentInput.appendChild(commentContentInput);

        if (!isPublic) {
            contentDivText.append(address, addressInput, start, startInput, comment, commentInput);
        } else {
            contentDivText.append(address, addressInput, start, startInput);
        }
        contentDiv.append(header, contentDivText);
        popupDiv.append(contentDiv);
        return popupDiv;
    },
    createLink(pathWithoutId, text, icon, marker, onclick = '') {
        const link = document.createElement('a');
        const linkText = document.createTextNode(text);
        const linkIcon = document.createElement('i');
        linkIcon.classList.add('pi', icon, 'info-window-content-actions-icon');
        if (pathWithoutId !== undefined && marker !== null) {
            link.setAttribute("href", pathWithoutId + marker.id);
        } else if (pathWithoutId !== undefined) {
            link.setAttribute("href", pathWithoutId);
        } else  {
            link.setAttribute("href", "#");
            link.setAttribute("onclick", onclick);
        }
        link.classList.add("info-window-content-actions-href");
        link.appendChild(linkIcon);
        link.appendChild(linkText);
        return link;
    },
    getBearerToken() {
        let token = localStorage.getItem("token")
        if (token !== null) {
            return "Bearer " + token;
        } else {
            return null;
        }
    },
    createPlannedOutageInfoWindowContent(marker, isPublic) {
        const popupDiv = document.createElement("div");
        popupDiv.classList.add("info-window");

        const header = document.createElement("header");
        header.classList.add("info-window-header");
        header.classList.add("info-window-header__planned")

        const contentDiv = document.createElement("div");
        contentDiv.classList.add("info-window-content");

        const contentDivText = document.createElement("div");
        contentDivText.classList.add("info-window-content-text");

        const contentDivDate = document.createElement("div");
        contentDivDate.classList.add("info-window-content-text");
        contentDivDate.classList.add("info-window-content-text__date");

        const address = document.createElement("p");
        let addressContent = document.createTextNode("Obszar planowanego wyłączenia:");

        address.classList.add("info-window-content-address");
        address.classList.add("info-window-content-address__planned");

        const addressInput = document.createElement("ul");


        for (let i = 0; i < marker.plannedOutages.addressList.length; i++) {
            const singleAddress = document.createElement("li")
            let addressContentInput;
            if (marker.plannedOutages.addressList[i].streetType) {
                addressContentInput = document.createTextNode(marker.plannedOutages.addressList[i].streetType + " " + marker.plannedOutages.addressList[i].streetName + " " + marker.plannedOutages.addressList[i].residenceNumber);
            } else {
                addressContentInput = document.createTextNode(marker.plannedOutages.addressList[i].streetName + " " + marker.plannedOutages.addressList[i].residenceNumber);
            }
            singleAddress.appendChild(addressContentInput);
            addressInput.appendChild(singleAddress);
        }
        addressInput.classList.add("info-window-content-address-input");

        const start = document.createElement("p");
        start.classList.add("info-window-content-start-data")

        const startInput = document.createElement("p");
        startInput.classList.add("info-window-content-start-data-input")

        const end = document.createElement("p");
        end.classList.add("info-window-content-end-data")

        const endInput = document.createElement("p");
        endInput.classList.add("info-window-content-end-data-input")

        const comment = document.createElement("p");
        const commentContent = document.createTextNode("Dodatkowe informacje:");
        comment.classList.add("info-window-content-comment")

        let commentInput = document.createElement("p");
        let commentContentInput = document.createTextNode(marker.comment ? marker.comment : "Nie ma komentarza.");
        commentInput.classList.add("info-window-content-comment-input");

        let commentLink = document.createElement("a");
        commentLink.classList.add("info-window-content-comment-link");

        let headerContent, startContent, startContentInput, endContent, endContentInput, commentLinkContent;

        if (marker.type === "PLANNED" && marker.showDate === true) {
            headerContent = document.createTextNode("Planowane wyłączenie");
            startContent = document.createTextNode("Czas rozpoczęcia:");
            startContentInput = document.createTextNode(new Date(marker.plannedOutages.start).toLocaleString("pl-PL", TimeUtils.options));
            endContent = document.createTextNode(" Przewidywany czas zakończenia:");
            endContentInput = document.createTextNode(new Date(marker.plannedOutages.end).toLocaleString("pl-PL", TimeUtils.options));
        } else if (marker.type === "PLANNED") {
            headerContent = document.createTextNode("Planowane wyłączenie");
            startContent = document.createTextNode("Trwa planowane wyłączenie.");
            commentContentInput = document.createTextNode("Przewidywany czas zakończenia prac znajdziesz w zakładce");
            commentLinkContent = document.createTextNode("Planowane wyłączenia");
            commentLink.href = "/public/planned"
        }
        address.appendChild(addressContent);
        // addressInput.appendChild(addressContentInput);
        header.appendChild(headerContent);
        if (marker.type === "PLANNED" && marker.showDate) {
            start.appendChild(startContent);
            startInput.appendChild(startContentInput);
            end.appendChild(endContent);
            endInput.appendChild(endContentInput);
        } else if (marker.type === "PLANNED") {
            start.appendChild(startContent);
            commentLink.appendChild(commentLinkContent)
        }

        comment.appendChild(commentContent);
        commentInput.appendChild(commentContentInput);

        if (isPublic === true || isPublic === undefined) {
            if (marker.type === "PLANNED" && marker.showDate) {
                contentDivText.append(address, addressInput);
                contentDivDate.append(start, startInput, end, endInput)
            } else if (marker.type === "PLANNED") {
                contentDivText.append(address, addressInput);
                contentDivDate.append(start, startInput, commentInput, commentLink)
            }
        } else {
            contentDivText.append(address, addressInput, comment, commentInput);
            contentDivDate.append(start, startInput, end, endInput);
        }

        contentDiv.classList.add("info-window-content__planned")

        contentDiv.append(contentDivText, contentDivDate);
        popupDiv.append(header, contentDiv);
        return popupDiv;
    },
    createConfirmedOutageInfoWindowContent(marker, isPublic) {
        const popupDiv = document.createElement("div");
        popupDiv.classList.add("info-window");

        const header = document.createElement("header");
        header.classList.add("info-window-header");
        header.classList.add("info-window-header__confirmed")

        const contentDiv = document.createElement("div");
        contentDiv.classList.add("info-window-content");

        const contentDivText = document.createElement("div");
        contentDivText.classList.add("info-window-content-text");

        const contentDivDate = document.createElement("div");
        contentDivDate.classList.add("info-window-content-text");
        contentDivDate.classList.add("info-window-content-text__date");

        const address = document.createElement("p");
        let addressContent;
        addressContent = document.createTextNode("Obszar potwierdzonej awarii:")

        address.classList.add("info-window-content-address");
        address.classList.add("info-window-content-address__confirmed");

        const addressInput =document.createElement("ul");

        for (let i = 0; i < marker.confirmedOutages.addressList.length; i++) {
            const singleAddress = document.createElement("li")
            const addressContentInput = document.createTextNode(marker.confirmedOutages.addressList[i].streetType + " " + marker.confirmedOutages.addressList[i].streetName + " " + marker.confirmedOutages.addressList[i].residenceNumber);
            singleAddress.appendChild(addressContentInput);
            addressInput.appendChild(singleAddress);
        }
        addressInput.classList.add("info-window-content-address-input");


        const start = document.createElement("p");
        start.classList.add("info-window-content-start-data")

        const startInput = document.createElement("p");
        startInput.classList.add("info-window-content-start-data-input")

        const end = document.createElement("p");
        end.classList.add("info-window-content-end-data")

        const endInput = document.createElement("p");
        endInput.classList.add("info-window-content-end-data-input")

        const comment = document.createElement("p");
        const commentContent = document.createTextNode("Dodatkowe informacje:");
        comment.classList.add("info-window-content-comment")

        let commentInput = document.createElement("p");
        let commentContentInput = document.createTextNode(marker.comment ? marker.comment : "Nie ma komentarza.");
        commentInput.classList.add("info-window-content-comment-input");

        let commentLink = document.createElement("a");
        commentLink.classList.add("info-window-content-comment-link");

        let headerContent, startContent, startContentInput, endContent, endContentInput, commentLinkContent;

        headerContent = document.createTextNode("Potwierdzona awaria");
        commentContentInput = document.createTextNode("Brygady Pogotowia Energetycznego Stoen Operator są na miejscu i pracują nad przywróceniem zasilania.");

        address.appendChild(addressContent);
        // addressInput.appendChild(addressContentInput);
        header.appendChild(headerContent);

        comment.appendChild(commentContent);
        commentInput.appendChild(commentContentInput);

        if (isPublic === true || isPublic === undefined) {
            contentDivText.append(address, addressInput);
            contentDivDate.append(commentInput)
        } else {
            contentDivText.append(address, addressInput, comment, commentInput);
            contentDivDate.append(start, startInput, end, endInput);
        }
        contentDiv.classList.add("info-window-content__confirmed")
        contentDiv.append(contentDivText, contentDivDate);
        popupDiv.append(header, contentDiv);
        return popupDiv;
    },
    createExtensiveOutageInfoWindowContent(marker, isPublic) {
        const popupDiv = document.createElement("div");
        popupDiv.classList.add("info-window-extensive");

        const header = document.createElement("header");
        header.classList.add("info-window-header");
        header.classList.add("info-window-header__extensive")

        const contentDiv = document.createElement("div");
        contentDiv.classList.add("info-window-content");

        const contentDivText = document.createElement("div");
        contentDivText.classList.add("info-window-content-text");

        const contentDivDate = document.createElement("div");
        contentDivDate.classList.add("info-window-content-text");
        contentDivDate.classList.add("info-window-content-text__date");

        const start = document.createElement("p");
        start.classList.add("info-window-content-start-data")

        const startInput = document.createElement("p");
        startInput.classList.add("info-window-content-start-data-input")

        const end = document.createElement("p");
        end.classList.add("info-window-content-end-data")

        const endInput = document.createElement("p");
        endInput.classList.add("info-window-content-end-data-input")

        const comment = document.createElement("p");
        const commentContent = document.createTextNode("Dodatkowe informacje:");
        comment.classList.add("info-window-content-comment")

        let commentInput = document.createElement("p");
        let commentContentInput = document.createTextNode(marker.description ? marker.description : "Nie ma komentarza.");
        commentInput.classList.add("info-window-content-comment-input");

        comment.appendChild(commentContent);
        commentInput.appendChild(commentContentInput);

        let headerContent;
        headerContent = document.createTextNode("Rozległa awaria");
        header.appendChild(headerContent);

        contentDivText.append(comment, commentInput);
        contentDivDate.append(start, startInput, end, endInput);

        contentDiv.classList.add("info-window-content__extensive")
        contentDiv.append(contentDivText, contentDivDate);
        popupDiv.append(header, contentDiv);
        return popupDiv;
    },
    createInfoWindowContent(marker, isPublic) {
        switch (marker.type) {
            case "CALL_CENTER":
            case "WEB":
                return this.createUserReportInfoWindowContent(marker, isPublic);
            case "PLANNED":
                return this.createPlannedOutageInfoWindowContent(marker, isPublic);
            case "CONFIRMED":
                return this.createConfirmedOutageInfoWindowContent(marker, isPublic);
            case "EXTENSIVE":
                return this.createExtensiveOutageInfoWindowContent(marker, isPublic);
            default:
                return "Nieznany typ zdarzenia";
        }
    },

    createAreaOutageMarkerOptions(marker) {
        return {
            id: marker.reportId ? marker.reportId : marker.id,
            start: marker.outageStart,
            end: marker.outageEnd,
            addressList: marker.addressList ? marker.addressList : marker.addresses,
            area: marker.area ? marker.area : marker.points,
            addressesCount: marker.addressesCount ? marker.addressesCount : (marker.addressList ? marker.addressList.length : 0),
            description: marker.description ? marker.description : undefined
        };
    },
    adjustAreaOutageMarkers(markers) {
        let adjustedMarkers = [];
        markers.forEach((marker) => adjustedMarkers.push(this.createAreaOutageMarkerOptions(marker)));
        return adjustedMarkers;
    },

    createUserReportMarkerOptions(marker) {
        return {
            id: marker.reportId,
            start: marker.date,
            areaId: marker?.area?.id,
            comment: marker.comment,
            address: `${marker.streetType + " " + marker.streetName + " " + marker.residenceNumber + ", " + marker.city}` + `${marker.wesola ? " Wesoła" : ""}`,
            position: marker.coordinateX && marker.coordinateX !== "" && marker.coordinateY && marker.coordinateY !== "" ? {lat: Number(marker.coordinateY), lng: Number(marker.coordinateX)} : null,
            type: marker.type,
        };
    },
    adjustUserReportsMarkers(markers) {
        let adjustedMarkers = [];
        markers.forEach((marker) => adjustedMarkers.push(this.createUserReportMarkerOptions(marker)));
        return adjustedMarkers;
    },
    createAreaOptions(area) {
        return {
            id: area.id,
            paths: [area.shape[0].outerRing, area.shape[0].innerRings],
            strokeColor: "#EA1B0A",
            strokeOpacity: 0.6,
            strokeWeight: 2,
            fillColor: "#EA1B0A",
            fillOpacity: 0.35,
            visible: false,
        };
    },

    adjustAreas(areas) {
        let adjustedAreas = [];
        areas.forEach((area) => adjustedAreas.push(this.createAreaOptions(area)));
        return adjustedAreas;
    },
    createAreaOutlineOptions(area) {
        return {
            id: area.id,
            paths: [area.shape[0].outerRing, area.shape[0].innerRings],
            strokeColor: "#EA1B0A",
            strokeOpacity: 0.25,
            strokeWeight: 2,
            fillOpacity: 0,
            visible: true,
        };
    },

    adjustAreaOutlines(areas) {
        let adjustedAreas = [];
        areas.forEach((area) => adjustedAreas.push(this.createAreaOutlineOptions(area)));
        return adjustedAreas;
    },

    geocoder: null,

    getGeocoder() {
        if (this.geocoder == null) {
            this.geocoder = new google.maps.Geocoder();
        }
        return this.geocoder;
    },
}
