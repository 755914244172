import {createWebHistory, createRouter} from "vue-router";
import EonLoginView from "@/views/EonLoginView";
import EonPublicLoginView from "@/views/EonPublicLoginView";
import LayoutSimple from "@/views/layout/LayoutSimple";
import LayoutAdmin from "@/views/layout/LayoutAdmin";
import layoutAccepter from "@/views/layout/LayoutAccepter";
import AdminUsersView from "@/views/users/UsersView";
import PlannedOutageReportPublicView from "@/views/plannedOutageReport/PlannedOutageReportPublicView";
import PlannedOutageReportMasterView from "@/views/plannedOutageReportMaster/PlannedOutageReportMasterView";
import PlannedOutageReportAcceptView from "@/views/plannedOutageReportAccept/PlannedOutageReportAcceptView";
import AdminAreasView from "@/views/admin/AreasView";
import UserEditView from "@/views/users/UserEditView";
import UserAddView from "@/views/users/UserAddView";
import LayoutMap from "@/views/layout/LayoutMap";
import PublicMapView from "@/views/public/PublicMapView";
import EonPasswordRemindView from "@/views/EonPasswordRemindView";
import UserAddReportError from "@/views/public/components/UserAddReportError";
import EonChangePasswordView from "@/views/EonChangePasswordView";
import HomePageView from "@/views/public/HomePageView";
import LayoutPublic from "@/views/layout/LayoutPublic";
import PlannedOutageReportMasterAddEditView
    from "@/views/plannedOutageReportMaster/PlannedOutageReportMasterAddEditView";
import PlannedOutageReportMasterShowView
    from "@/views/plannedOutageReportMaster/PlannedOutageReportMasterShowView";
import UserProfileView from "@/views/users/UserProfileView";
import LayoutDispatcher from "@/views/layout/LayoutDispatcher";
import {SystemRole} from "@/utils/SystemRole";
import EonForbiddenView from "@/views/EonForbiddenView";
import PublicAddReportView from "@/views/public/PublicAddReportView";
import AddUserReportCheck from "@/views/public/components/AddUserReportCheck";
import AddUserReportLocation from "@/views/public/components/AddUserReportLocation";
import AddUserReportDescription from "@/views/public/components/AddUserReportDescription";
import AddUserReportDetails from "@/views/public/components/AddUserReportDetails";
import AddUserReportCompleted from "@/views/public/components/AddUserReportCompleted";
import EonNotFoundView from "@/views/EonNotFoundView";
import EonErrorView from "@/views/EonErrorView";
import AboutView from "@/views/static/AboutView";
import PrivacyPolicyView from "@/views/static/PrivacyPolicyView";
import FAQView from "@/views/static/FAQView";
import PollView from "@/views/public/PollView";
import MaintenanceView from "@/views/admin/MaintenanceView";
import layoutCallCenter from "@/views/layout/LayoutCallCenter";
import PlannedOutageReportCallCenterView
    from "@/views/plannedOutrageReportCallCenter/PlannedOutageReportCallCenterView";
import EonRegisterView from "@/views/public/EonRegisterView";
import EonConfirmEmailView from "@/views/EonConfirmEmailView";
import EonStopSendEmailView from "@/views/EonStopSendEmailView";
import PublicUserSettingsView from "@/views/public/PublicUserSettingsView";
import PublicUserReportsView from "@/views/public/PublicUserReportsView";
import PublicUserAddressView from "@/views/public/PublicUserAddressView";

function hasQueryParams(route) {
    return !!Object.keys(route.query).length;
}

function sortByLength(array) {
    return array.sort((a, b) => a.length - b.length);
}

// Sprawdza, czy dana ścieżka składa się z innej podanej i konkretnego sufiksu
// Przyjmuje jako argumenty dwuelementową tablicę ścieżek oraz sufiks
function areRelatedPaths(paths, suffix) {
    paths = sortByLength(paths);
    const shorterPath = paths[0];
    const longerPath = paths[1];
    return (longerPath.startsWith(shorterPath) && suffix === longerPath.slice(shorterPath.length));
}

// Przy przejściach między widokami mapy a odpowiadającymi im widokami tabel
// kopiuje wybrane search criteria do urla
function copyQueryParams(to, from, next, suffix) {
    if (areRelatedPaths([to.path, from.path], suffix)
        && !hasQueryParams(to) && hasQueryParams(from)) {
        delete from.query.pagelimit;
        next({name: to.name, query: from.query});
    } else {
        next();
    }
}

const routes = [
    {
        path: "/",
        redirect: "/public",
    },
    {
        component: LayoutPublic,
        path: "/",
        children: [
            {
                path: "/",
                name: "public",
                component: HomePageView,
            },
        ],
    },
    {
        component: LayoutAdmin,
        path: "/admin",
        name: "admin",
        meta: {requiresAuth: true, roles: [SystemRole.ADMIN]},
        children: [
            {
                path: "/admin/users",
                name: "usersAdmin",
                component: AdminUsersView,
            },
            {
                path: "/admin/areas",
                name: "areasAdmin",
                component: AdminAreasView,
            },
            // Views from users view:
            {
                path: "/admin/user/add",
                name: "userAddByAdmin",
                component: UserAddView,
            },
            {
                path: "/admin/user/edit/:id",
                name: "userEditByAdmin",
                component: UserEditView,
            },
            {
                path: "/admin/user/profile",
                name: "userProfileAdmin",
                component: UserProfileView,
            },
            {
                path: "/admin/maintenance",
                name: "maintenance",
                component: MaintenanceView,
            },
        ],
    },
    {
        component: LayoutDispatcher,
        path: "/master",
        name: "master",
        meta: {requiresAuth: true, roles: [SystemRole.DISPATCHER]},
        children: [
            {
                path: "/master/planned",
                name: "plannedMaster",
                component: PlannedOutageReportMasterView,
                beforeEnter: ((to, from, next) => {
                    copyQueryParams(to, from, next, "/map");
                }),
            },
            // Profile view:
            {
                path: "/master/user/profile",
                name: "userProfileMaster",
                component: UserProfileView,
            },
            // Views from planned outages:
            {
                path: "/master/planned/add",
                name: "plannedOutageReportAddByMaster",
                component: PlannedOutageReportMasterAddEditView,
            },
            {
                path: "/master/planned/edit/:id",
                name: "plannedOutageReportEditByMaster",
                component: PlannedOutageReportMasterAddEditView,
                props: {edit: true},
            },
            {
                path: "/master/planned/show/:id",
                name: "plannedOutageReportMasterShow",
                component: PlannedOutageReportMasterShowView,
            },
        ],
    },
    {
        component: layoutCallCenter,
        path: "/callcenter",
        name: "callcenter",
        meta: {requiresAuth: true, roles: [SystemRole.CALLCENTER]},
        children: [
            {
                path: "/callcenter/planned",
                name: "plannedCallCenter",
                component: PlannedOutageReportCallCenterView,
                beforeEnter: ((to, from, next) => {
                    copyQueryParams(to, from, next, "/map");
                }),
            },
            {
                path: "/callcenter/planned/show/:id",
                name: "plannedOutageReportCallCenterShow",
                component: PlannedOutageReportMasterShowView,
            },
            {
                path: "/callcenter/user",
                name: "callcenterUser",
                component: UserProfileView,
            },
        ],
    },
    {
        component: layoutAccepter,
        path: "/accepter",
        name: "accepter",
        meta: {requiresAuth: true, roles: [SystemRole.ACCEPTER]},
        children: [
            {
                path: "/accepter/planned",
                name: "plannedAccept",
                component: PlannedOutageReportAcceptView,
                beforeEnter: ((to, from, next) => {
                    copyQueryParams(to, from, next, "/map");
                }),
            },
            {
                path: "/accepter/planned/show/:id",
                name: "plannedOutageReportAcceptShow",
                component: PlannedOutageReportMasterShowView,
            },
            {
                path: "/accepter/user",
                name: "accepterUser",
                component: UserProfileView,
            },
        ],
    },
    {
        name: "layoutSimple",
        component: LayoutSimple,
        path: "/",
        children: [
            {
                path: "/login",
                name: "login",
                component: EonLoginView,
            },
            {
                path: "/public/remind",
                name: "publicRemindPassword",
                component: EonPasswordRemindView,
            },
            {
                path: "/remind",
                name: "remindPassword",
                component: EonPasswordRemindView,
            },
            {
                path: "/change-password",
                name: "changePassword",
                component: EonChangePasswordView,
            },
            {
                path: "/public/change-password",
                name: "publicChangePassword",
                component: EonChangePasswordView,
            },
            {
                path: "/public/confirm-email",
                name: "confirmEmail",
                component: EonConfirmEmailView,
            },
            {
                path: "/public/stop-send-email",
                name: "stopSendEmail",
                component: EonStopSendEmailView,
            },
            {
                path: "/forbidden",
                name: "forbidden",
                component: EonForbiddenView,
            },
            {
                path: "/:pathMatch(.*)*",
                name: "notfound",
                component: EonNotFoundView,
            },
            {
                path: "/error",
                name: "error",
                component: EonErrorView,
            },
            {
                path: "/public/register",
                name: "register",
                component: EonRegisterView,
            },
            {
                path: "/public/login",
                name: "publicLogin",
                component: EonPublicLoginView,
            },
        ],
    },
    {
        component: LayoutMap,
        path: "/",
        children: [
            {
                path: "/public",
                name: "publicMapView",
                component: PublicMapView,
            },
            {
                path: "/public/:lng/:lat",
                name: "publicMapViewWithLocation",
                component: PublicMapView,
            },
            {
                path: "/public/about",
                name: "about",
                component: AboutView,
            },
            {
                path: "/public/privacy-policy",
                name: "privacyPolicy",
                component: PrivacyPolicyView,
            },
            {
                path: "/public/faq",
                name: "faq",
                component: FAQView,
            },
            {
                path: "/public/planned",
                name: "plannedDPublic",
                component: PlannedOutageReportPublicView,
            },
            {
                path: "/public/poll",
                name: "poll",
                component: PollView,
                // beforeEnter: ((to, from, next) => {
                //     copyQueryParams(to, from, next, "/map");
                // }),
            },
            {
                path: "/public/user/settings",
                name: "publicUserSettings",
                component: PublicUserSettingsView,
                meta: {requiresAuth: true, roles: [SystemRole.PUBLIC]},
            },
            {
                path: "/public/user/reports",
                name: "publicUserReportsView",
                component: PublicUserReportsView,
                meta: {requiresAuth: true, roles: [SystemRole.PUBLIC]},
            },
            {
                path: "/public/user/address",
                name: "publicUserAddress",
                component: PublicUserAddressView,
                meta: {requiresAuth: true, roles: [SystemRole.PUBLIC]},
            },
        ],
    },
    {
        component: PublicAddReportView,
        path: "/",
        name: "publicAddReport",
        children: [
            {
                component: AddUserReportLocation,
                name: "publicAddReportLocation",
                path: "/public/add-report/1",
            },
            // {
            //     component: AddUserReportLocation,
            //     name: "publicAddReportLocationWithLocation",
            //     path: "/public/add-report/1/:lng/:lat",
            // },
            {
                component: AddUserReportDetails,
                name: "publicAddReportDetails",
                path: "/public/add-report/2",
                props: true,
            },
            // {
            //     component: AddUserReportCheck,
            //     name: "publicAddReportCheckWithLocation",
            //     path: "/public/add-report/2/:lng/:lat",
            // },
            {
                component: AddUserReportDescription,
                name: "publicAddReportDescription",
                path: "/public/add-report/3",
                props: true,
            },
            // {
            //     component: AddUserReportDescription,
            //     name: "publicAddReportDescriptionWithLocation",
            //     path: "/public/add-report/3/:lng/:lat",
            // },
            {
                component: AddUserReportCheck,
                name: "publicAddReportCheck",
                path: "/public/add-report/4",
                props: true,
            },
            // {
            //     component: AddUserReportDetails,
            //     name: "publicAddReportDetailsWithLocation",
            //     path: "/public/add-report/4/:lng/:lat",
            // },
            {
                component: AddUserReportCompleted,
                name: "publicAddReportAlreadyCompleted",
                path: "/public/add-report/5/:alreadyCompleted",
            },
            {
                component: AddUserReportCompleted,
                name: "publicAddReportCompleted",
                path: "/public/add-report/5/",
            },
            // {
            //     component: AddUserReportCompleted,
            //     name: "publicAddReportCompletedWithLocation",
            //     path: "/public/add-report/5/:lng/:lat",
            // },
            {
                component: UserAddReportError,
                name: "publicAddReportError",
                path: "/public/add-report/error",
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
//
// router.beforeEach((to, from, next) => {
//     if (!hasQueryParams(to) && hasQueryParams(from)) {
//         next({name: to.name, query: from.query});
//     } else {
//         next();
//     }
// });

export default router;
