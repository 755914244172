<template>
    <div class="main-field">
        <section class="content">
            <div class="error-code">
                <h1>500</h1>
                <i class="pi pi-times"/>
            </div>
            <h1>Błąd</h1>
            <p>Prosimy skontaktować się z administratorem systemu</p>
            <router-link class="redirect-button" v-slot="{navigate}" :to="{name: 'public'}">
                <EonOutlinedButton class="inverted bordeaux" icon="pi pi-undo" label="Powrót na stronę główną"
                                   extension="100" @click="navigate"/>
            </router-link>
        </section>
    </div>
</template>

<script>
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";

    export default {
        name: "ForbiddenView",
        components: {EonOutlinedButton},
    };
</script>

<style lang="less" scoped>
@import "../assets/theme/variable.less";

.main-field {
    display: flex;
    justify-content: center;
}

.content {
    width: 35em;
    height: 25em;
    background: @tint-bordeaux-00;
    color: @color-white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1em;
}

.error-code {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.redirect-button {
    display: flex;
    justify-content: center;
}

h1 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.19;
    margin: 0.3em;
}

p {
    font-size: 1.2rem;
    font-weight: 400;
}

i {
    scale: 2;
}

</style>
