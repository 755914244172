<template>
    <div>
        <div v-if="requestSent === false" class="eon-stop-send-email-field">
            <h2>
                {{ $t('message.register.stopSendEmailConfirmation') }}
            </h2>
            <div class="buttons">
                <EonButton class="left-0 inverted turquoise" label="Tak, rezygnuję" @click="stopSendEmail"/>
                <router-link :to="{name: 'publicMapView'}">
                    <EonOutlinedButton class="right-0 inverted turquoise" label="Anuluj"/>
                </router-link>
            </div>
        </div>
        <div v-else class="eon-stop-send-email-field">
            <h2 v-if="responseStatus === 200">
                {{ $t('message.register.stopSendEmailSuccessMessage') }}
            </h2>
            <h2 v-else>
                {{ $t('message.register.stopSendEmailErrorMessage') }}
            </h2>
            <div>
                <router-link :to="{name: 'publicMapView'}">
                    <EonButton class="right-0 inverted turquoise" label="Widok główny"/>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import EonButton from "@/components/form/EonButton";
    import {unsubscribeFromEmailNotifications} from "@/swagger/vue-api-client";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";

    export default {
        name: "EonStopSendEmailView",
        components: {EonButton, EonOutlinedButton},

        data() {
            return {
                responseStatus: null,
                requestSent: false,
            };
        },

        methods: {
            stopSendEmail() {
                this.requestSent = true;
                unsubscribeFromEmailNotifications({hash: this.$route.query.emailConsentToken})
                    .then((response) => {
                        this.responseStatus = response.status;
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
@import "assets/less/eon-stop-send-email.less";
</style>
