<template>
    <div class="menu-field">
        <div class="menu-inner-field">
            <TabMenu :model="leftItems" />
            <div class="menu-right-side">
                <TabMenu class="menu-right-items" :model="rightItems">
                    <template #item="{item}">
                        <!-- eslint-disable-next-line -->
                        <div class="menu-item" @click="handleItemClick(item)">
                            <a class="menu-icon" :style="item.submenuVisible ? 'color: #EA1B0A' : 'color: #39393A'"
                               @click="item.onClick" :href="item.to">
                                <span class="accessibility-text">{{item.label}}</span>
                                <i :class="item.icon" aria-hidden="true"/>
                            </a>
                            <div v-if="item.submenuVisible" class="submenu">
                                <router-link v-for="subItem in item.items" :key="subItem.label"
                                             :to="subItem.to">
                                    {{ subItem.label }}
                                </router-link>
                                <a href="/public/regulamin_notyfikacje.pdf" target="_blank">Regulamin</a>
                            </div>
                        </div>
                    </template>
                </TabMenu>
                <EonButton v-if="showLoginButton()" label="Zaloguj"
                           extension="-50" @click="toggleLoginSidebar"/>
                <div class="logo-container logo">
                    <a href="https://stoen.pl/">
                        <img class="logo" src="../../assets/logoStoenRed.svg" alt="Logo" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import TabMenu from "primevue/tabmenu";
    import EonButton from "@/components/form/EonButton";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "EonTabMenu",
        components: {
            EonButton, TabMenu,
        },

        props: {
            leftItems: {
                type: Array,
            },
            rightItems: {
                type: Array,
            },
            isUserLoggedIn: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            showLoginButton() {
                return SystemRole.getUserRole() === null && !SystemRole.isPublic();
            },
            toggleLoginSidebar() {
                this.$emit("toggle-login-sidebar");
            },
            handleItemClick(item) {
                this.$emit("handle-item-click", item);
            },
        },
    };

</script>
<style lang="less">
@import "../assets/less/tabmenu.less";
</style>
<style scoped>
.submenu {
    left: 50%;
    transform: translateX(-60%);
    position: absolute;
    width: 200px;
    top: 100%;
    background-color: white;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    margin-top: 5px;
    padding-bottom: 8px;
}
.submenu::before {
    content: '';
    position: absolute;
    top: 12px;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 10px solid black;
    border-color: transparent transparent white white;
    transform-origin: 0 0;
    box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.4);
    transform: rotate(135deg) translateX(-75%);
}

.submenu::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 6px;
    background-color: #1EA2B1;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.submenu a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: black;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.submenu a:hover {
    background-color: #f0f0f0;
}

.menu-item {
    height: 100%;
}
</style>
