<template>
    <EonPollDialog class="cookies">
        <h2> {{ $t('message.poll.dialogTitle') }} </h2>
        <p class="poll-message"> {{ $t('message.poll.dialogSubtitle') }} </p>
    </EonPollDialog>
    <EonCookies class="cookies">
    </EonCookies>
    <Toast position="top-center" group="tc" @close="onClose">
    </Toast>
    <Toast position="top-right" group="login" style="width: auto">
    </Toast>
    <div :class="['map-layout-wrapper', { 'map-layout-wrapper-with-sidebar': sidebarState.visible }]">
        <EonTabMenu :leftItems="menuLeftItems" :rightItems="isUserLoggedIn ? menuRightItems : null"
                    :key="isUserLoggedIn" @toggle-login-sidebar="toggleLoginSidebar"
                    @handle-item-click="handleItemClick"/>
        <Sidebar v-model:visible="sidebarState.login" position="right"
                 :modal="false" @hide="hideLoginSidebar()">
            <template #header>
                <div class="logo-container logo">
                    <a href="https://stoen.pl/">
                        <img class="logo" src="../../assets/logoStoenRed.svg" alt="Logo" />
                    </a>
                </div>
            </template>
            <div class="login-form">
                <VeeForm @submit="login">
                    <h2>{{$t('message.login.action.login')}}</h2>
                    <div class="login-inputs">
                        <EonInputText rules="required|email" name="login" v-model="loginForm.login"
                                      :label="$t('message.login.eonLogin')"/>
                        <EonPassword rules="required" name="password"
                                     v-model="loginForm.password"
                                     :label="$t('message.login.password')"/>

                    </div>
                    <div class="login-actions">
                        <EonButton :label="$t('message.login.action.login')"
                                   extension="-20" type="submit" />
                        <EonHref href="/public/remind" :label="$t('message.login.action.eonRemindPassword')"
                                 color="red"/>
                    </div>
                </VeeForm>
            </div>
            <h2>{{ 'Nie masz konta?' }}</h2>
            <EonOutlinedButton :label="$t('message.login.action.register')" type="button" @click="pushToRegisterView"
                               extension="-20" />
        </Sidebar>
        <button v-if="$route.name === 'publicMapView'" class="sidebar-button"
                @click="toggleInfoSidebar()" type="button">
            <span class="sidebar-button-text">Powiadomienia o przerwach</span>
        </button>
        <Sidebar v-model:visible="sidebarState.info" position="right"
                 :modal="false" @hide="hideInfoSidebar()">
            <template #header>
                <div class="logo-container logo">
                    <a href="https://stoen.pl/">
                        <img class="logo" src="../../assets/logoStoenRed.svg" alt="Logo" />
                    </a>
                </div>
            </template>
            <div class="sign-up-for-notifications flex flex-column justify-content-center">
                <h2>Chcesz wiedzieć,<br/>kiedy nie będzie prądu?</h2>
                <EonButton :label="$t('message.login.action.signUpHere')" type="button"
                           @click="pushToRegisterView" extension="-20" />
                <h2>aby otrzymywać<br/>powiadomienia o przerwach</h2>
                <div class="sidebar-icons">
                    <img src="@/assets/smartphone.svg" alt="smartphone"/>
                    <img src="@/assets/mail.svg" alt="email"/>
                </div>
                <div style="background-image: url('@/assets/mail.svg')"></div>
            </div>
        </Sidebar>
        <router-view @logout="logout"/>
    </div>
</template>

<script>
    import {Form as VeeForm} from "vee-validate";
    import EonTabMenu from "@/components/form/EonTabMenu";
    import EonPollDialog from "@/views/public/EonPollDialog";
    import Toast from "primevue/toast";
    import {getConfigs, publicLogin as loginRequest} from "@/swagger/vue-api-client";
    import {maintenanceEndKey, maintenanceShowKey, maintenanceStartKey} from "@/constants";
    import EonCookies from "@/components/form/EonCookies";
    import {SystemRole} from "@/utils/SystemRole";
    import Sidebar from "primevue/sidebar";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import EonHref from "@/components/form/EonHref";
    import EonInputText from "@/components/form/EonInputText";
    import EonPassword from "@/components/form/EonPassword";
    import EonButton from "@/components/form/EonButton";
    import {StatusCodes} from "@/utils/StatusCodes";
    import {useRouter} from "vue-router";

    const dateFormatter = new Intl.DateTimeFormat(
        "pl",
        {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
        },
    );

    export default {
        name: "LayoutMap",
        components: {
            EonButton,
            EonPassword,
            EonInputText,
            EonHref,
            EonOutlinedButton,
            Sidebar,
            EonCookies,
            EonTabMenu,
            EonPollDialog,
            Toast,
            VeeForm,
        },
        data() {
            return {
                sidebarState: {
                    login: false,
                    info: false,
                    visible: false,
                },
                loginForm: {
                    login: "",
                    password: "",
                },
                myHeight: "",
                maintenanceToastVisible: false,
                maintenanceData: {
                    start: null,
                    end: null,
                    show: false,
                },
                menuLeftItems: [
                    {
                        label: "Mapa",
                        to: "/public",
                    },
                    {
                        label: "Planowane wyłączenia",
                        to: "/public/planned",
                    },
                    {
                        label: "O Portalu",
                        to: "/public/about",
                    },
                    {
                        label: "Najczęściej zadawane pytania",
                        to: "/public/faq",
                    },
                    {
                        label: "Polityka prywatności",
                        to: "/public/privacy-policy",
                    },
                    {
                        label: "Ankieta",
                        to: "/public/poll",
                    },
                ],
                menuRightItems: [
                    {
                        id: "myAccount",
                        icon: "pi pi-user",
                        label: "Moje konto",
                        submenuVisible: false,
                        items: [
                            {
                                label: "Ustawienia",
                                to: "/public/user/settings",
                            },
                            {
                                label: "Moje adresy",
                                to: "/public/user/address",
                            },
                            {
                                label: "Moje zgłoszenia",
                                to: "/public/user/reports",
                            },
                        ],
                    },
                    {
                        id: "logout",
                        label: "Wyloguj",
                        icon: "pi pi-sign-out",
                    },
                ],
                cookiesPolicyUrl: process.env.VUE_APP_COOKIES_URL,
                isUserLoggedIn: SystemRole.isPublic(),
                router: useRouter(),
            };
        },
        mounted() {
            getConfigs().then((response) => {
                response.data.forEach((config) => {
                    if (config.key === maintenanceStartKey) {
                        this.maintenanceData.start = new Date(config.value);
                    } else if (config.key === maintenanceEndKey) {
                        this.maintenanceData.end = new Date(config.value);
                    } else if (config.key === maintenanceShowKey) {
                        this.maintenanceData.show = config.value === "true";
                    }
                });
                if (this.maintenanceData.show === true) {
                    this.showMaintenanceInfo();
                }
            });
            this.sidebarState.info = sessionStorage.getItem("sidebarShown") !== "true";
            sessionStorage.setItem("sidebarShown", "true");
        },
        methods: {
            successToast(message) {
                this.$toast.removeAllGroups();
                this.$toast.add({
                    severity: "success",
                    summary: message,
                    life: 3000,
                    closable: true,
                    group: "login",
                });
            },
            errorToast() {
                this.$toast.removeAllGroups();
                this.$toast.add({
                    severity: "warn",
                    summary: "Niepoprawne dane logowania",
                    life: 5000,
                    closable: true,
                    group: "login",
                });
            },
            login() {
                loginRequest({body: this.loginForm})
                    .then((response) => {
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("userId", response.data.userId);
                        localStorage.setItem("role", response.data.role);
                        localStorage.setItem("fullName", response.data.fullName);
                        localStorage.setItem("area", response.data.area);
                        localStorage.setItem("email", response.data.email);
                        localStorage.setItem("phone", response.data.phone);

                        this.sidebarState.visible = false;
                        this.sidebarState.login = false;
                        this.isUserLoggedIn = true;
                        this.loginForm = {
                            login: "",
                            password: "",
                        };
                        this.successToast("Zalogowano pomyślnie");
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case StatusCodes.UNAUTHORIZED:
                                this.badCredentials = true;
                                this.errorToast();
                                break;
                            case StatusCodes.SERVICE_UNAVAILABLE:
                                this.isBlocked = true;
                                this.badCredentials = false;
                                break;
                            case StatusCodes.FORBIDDEN:
                                this.badCredentials = true;
                                this.errorToast();
                                break;
                            default:
                        }
                    });
            },
            pushToRegisterView() {
                this.$router.push({name: "register"});
            },
            handleItemClick(item) {
                if (item.id === "logout") {
                    this.logout();
                }
                if (item.id === "myAccount") {
                    item.submenuVisible = !item.submenuVisible;
                }
            },
            toggleLoginSidebar() {
                this.sidebarState.login = !this.sidebarState.login;
                setTimeout(() => { this.sidebarState.visible = this.sidebarState.login; }, 200);
            },
            toggleInfoSidebar() {
                this.sidebarState.info = !this.sidebarState.info;
                setTimeout(() => { this.sidebarState.visible = this.sidebarState.info; }, 200);
            },
            hideLoginSidebar() {
                this.sidebarState.login = false;
                this.sidebarState.visible = false;
            },
            hideInfoSidebar() {
                this.sidebarState.info = false;
                this.sidebarState.visible = false;
            },
            showMaintenanceInfo() {
                if (!this.maintenanceToastVisible) {
                    const dateStart = dateFormatter.format(this.maintenanceData.start);
                    const dateEnd = dateFormatter.format(this.maintenanceData.end);

                    this.$toast.add(
                        {
                            // eslint-disable-next-line max-len
                            severity: "warn", summary: "Od " + dateStart + " do " + dateEnd + " planujemy prace serwisowe na Portalu planowanych wyłączeń i awarii. Serwis może być w tym czasie niedostępny. Przepraszamy za utrudnienia.", group: "tc",
                        },
                    );
                    this.maintenanceToastVisible = true;
                }
            },
            onClose() {
                this.maintenanceToastVisible = false;
            },
            logout(message) {
                localStorage.removeItem("token");
                localStorage.removeItem("userId");
                localStorage.removeItem("role");
                localStorage.removeItem("fullName");
                localStorage.removeItem("area");
                localStorage.removeItem("email");
                localStorage.removeItem("phone");

                this.isUserLoggedIn = false;
                if (this.router.currentRoute.meta.requiresAuth === true) {
                    this.$router.push({name: "publicMapView"});
                }

                this.successToast(message || "Zostałeś wylogowany z systemu");
            },
        },

    };
</script>

<style lang="less">
@import "../../components/assets/less/toast.less";
@import "../assets/less/view-with-tab-menu.less";
.p-sidebar-right {
    width: 25%;
    .p-sidebar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .p-sidebar-close {
            order: -1;
        }
    }
    .p-sidebar-content {
        margin: 0 1em;
    }
}
.map-layout-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.map-layout-wrapper-with-sidebar {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.maintenance-banner {
    width: 100%;
    height: 150%;
}

@media screen and (max-width: 1406px) {
    .map-layout-wrapper-with-sidebar {
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .p-sidebar-right {
        width: 30%;
    }
}

@media screen and (max-width: 1173px) {
    .map-layout-wrapper-with-sidebar {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .p-sidebar-right {
        width: 40%;
    }
}

@media screen and (max-width: 900px) {
    .p-sidebar-right {
        width: 100%;
    }
}

</style>
<style scoped>
.login-form {
    margin-bottom: 5em;
    .login-inputs {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 1em;
        margin-bottom: 2em;
    }
    .login-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}
.logo {
    height: 4em;
    padding-right: 1em;
    padding-left: 1em;
    padding-top: 0.5em;
}
.logo-container {
    display: flex;
    align-items: center;
    padding: 0;
    margin-left: 1em;
}
.sidebar-button {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 25%;
    width: 45px;
    background: #EA1B0A;
    color: white;
    border: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .sidebar-button-text {
        transform: rotate(90deg);
        font-weight: bold;
        white-space: nowrap;
    }

    &:hover {
        background: #B00402;
    }
}
.sign-up-for-notifications {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
}
.sidebar-icons {
    padding-top: 50px;
}
</style>
